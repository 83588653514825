import { CEBrandColors } from '@hallmark/web.styles.colors';
import { CEIconNames } from '@hallmark/web.styles.fonts.icomoon';
import AnimatedCrown from '../../assets/animated-crown.gif';
import userZoneAddPhotoIconUS from '../../assets/user-zone-add-photo-icon-us.svg';
import userZoneAddTextIconUS from '../../assets/user-zone-add-text-icon-us.svg';
import userZoneAddWamIconUS from '../../assets/user-zone-add-wam-icon-us.svg';
import { DeliveryModes } from '../../components/address-form/address-form-types';
import { HeaderVariants } from '../../components/print-on-demand-header/print-on-demand-header-types';
import { RegionalCodesList } from '../../global-types';
import { TextEditorDrawerType } from '../../global-types/regions-list';
import colorsList from '../../styles/util.scss';
import { RegionalSchema } from '../regional-config-types';

export const configCE: RegionalSchema = {
  deliveryMode: DeliveryModes.None,
  screen: {
    imageLoader: AnimatedCrown,
  },
  cardEditor: {
    faceTypeText: false,
    textEditorDrawerType: TextEditorDrawerType.Bottom,
    lastCardFaceRoute: 'preview',
  },
  cardSelectBar: {
    containerClass: 'footer-variant',
  },
  addressForm: {
    handleZipChange: false,
    shouldShowOneToManyEnvelopePreview: false,
  },
  saveProjects: {
    isSavedProjectsEnabled: false,
    saveButton: {
      type: 'SaveButton',
      iconName: CEIconNames.Save,
    },
    hasSignInDialog: true,
  },
  header: {
    saveDialogTheme: RegionalCodesList.ce,
    variant: HeaderVariants.CE,
  },
  scssStyles: {
    appBackground: colorsList[CEBrandColors.Ochre20],
    headerBackgroundMobile: 'transparent',
  },
  features: {
    // POD Write a Message includes a WAM button in the toolbar and a WAM button in user zones
    isPodWamEnabled: false,
  },
  userZones: {
    addTextIcon: userZoneAddTextIconUS,
    addPhotoIcon: userZoneAddPhotoIconUS,
    addWAMIcon: userZoneAddWamIconUS,
    areInstructionsEnabled: false,
  },
  isAddressRegulationToastEnabled: false,
  hasUsStates: false,
};
