import React from 'react';
import { LoadingIndicator } from '@hallmark/web.core.feedback.loading-indicator';
import { InlineLoaderProps } from '../image-upload-drawer-types';
import styles from '../image-upload-drawer.module.scss';

export const InlineLoader = ({ title }: InlineLoaderProps): React.ReactElement => (
  <div className={styles['loader-container']} role="alert" aria-live="polite" aria-label="Loading">
    <LoadingIndicator title={title} isCenteredInParent={true} parentHeight="120px" />
  </div>
);
