import React from 'react';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { useInitializationDataContext } from '../../context/data-context';
import styles from './delete-images-confirmation-dialog.module.scss';
import { DeleteImagesConfirmationDialogProps } from './delete-images-dialog-types';
import { Buttons } from './fragments/buttons';
import { Message } from './fragments/message';

export const DeleteImagesConfirmationDialog = ({
  domId = 'delete-confirmation-dialog',
  title = '',
  isOpen,
  onClose,
  onConfirm,
  actionButtonText,
  cancelButtonText,
  contentTitle,
  contentText,
}: DeleteImagesConfirmationDialogProps): React.ReactElement => {
  const { initializedDataState } = useInitializationDataContext();
  const { isUK } = initializedDataState;

  const content = (
    <div className={styles.container}>
      <Message contentTitle={contentTitle} contentText={contentText} />
      <Buttons
        onConfirm={onConfirm}
        onClose={onClose}
        actionButtonText={actionButtonText}
        cancelButtonText={cancelButtonText}
      />
    </div>
  );

  return (
    <Dialog
      id={domId}
      testId={'delete-image-confirmation-dialog'}
      hasGrayBackground={false}
      type={DialogContentType.Modal}
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      footerType={DialogFooterType.Passive}
      closeButtonId={`${domId}-close-button`}
      locale={isUK ? 'uk' : 'us'}
      addDialogClass={styles.dialogWrapper}
    >
      {content}
    </Dialog>
  );
};
