import { CardType, PreviewLabel } from '../../global-types';

/**
 * Gets appropriate label to display under preview thumbnail
 *
 * @param cardType Type of card. P preview displays 2 faces, where S&S displays 3 faces
 * @param index Index of asset in assets array (0 being the front)
 * @returns String equal to 'F' or 'I'
 */
export const getPreviewLabel = (cardType: CardType, index: number, isOneToMany: boolean): PreviewLabel => {
  if (cardType === CardType.SAS) {
    // For S&S, we generate preview for only 1 face -- Inside Right
    return PreviewLabel.IR;
  }
  if (cardType === CardType.POD || cardType === CardType.DG) {
    // For 1-to-many cards, we generate 2 previews -- front and back
    // For 1-to-1 POD cards, we generate 2 previews -- front and inside
    switch (index) {
      case 0:
        return PreviewLabel.F;
      case 1:
        return isOneToMany ? PreviewLabel.B : PreviewLabel.I;
      default:
        return PreviewLabel.BLANK;
    }
  }
  return PreviewLabel.BLANK;
};
