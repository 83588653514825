import React, { useState } from 'react';
import { Button } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { DialogBrandColors } from '@hallmark/web.core.feedback.dialog/dist/dialog-types';
import { TypographyVariants, Typography } from '@hallmark/web.core.typography.typography';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useInitializationDataContext } from '../../context/data-context';
import { AddressBox } from '../address-box/address-box';
import { AddressConfirmationProps } from './address-confirmation-types';
import styles from './address-confirmation.module.scss';
import { useAddressValidationText } from './hooks/useAddressValidationText';

const AddressConfirmation = ({
  isOpen,
  close,
  addressesToValidate,
  onSkipValidation,
  onEditAddress,
  showStepper,
}: AddressConfirmationProps) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { initializedDataState } = useInitializationDataContext();
  const { isUK } = initializedDataState;

  const currentValidationAddress = addressesToValidate[`${activeIndex}`];
  const { validationTitle, validationBody, addressTypeDisplay } = useAddressValidationText({
    currentValidationAddress,
  });

  return (
    <Dialog
      type={DialogContentType.FeatureModal}
      isOpen={isOpen}
      title={validationTitle}
      onClose={close}
      addDialogClass={styles['address-confirmation-dialog']}
      footerType={DialogFooterType.SingleButton}
      locale={isUK ? 'uk' : 'us'}
      accentIcon={IconNames.AddressBookBold}
      accentIconColor={DialogBrandColors.White}
      hasGrayBackground={false}
      actionButton={''}
    >
      <div className={styles['suggested-address-content']}>
        <div className={styles['suggested-address-description']}>
          <Typography variant={TypographyVariants.Body} addClass={styles['suggested-address-body']}>
            {validationBody}
          </Typography>
        </div>
      </div>
      <div className={styles['bottom-content']}>
        <AddressBox
          address={currentValidationAddress.address}
          addressTypeDisplay={addressTypeDisplay}
          onEditAddress={() => onEditAddress(currentValidationAddress.step)}
          addressesToValidate={addressesToValidate.length}
          activeIndex={activeIndex}
          showStepper={showStepper}
        />
        <div className={styles['modal-actions']}>
          <Button
            addClass={styles['confirm-address-btn']}
            click={() => {
              if (addressesToValidate?.length === activeIndex + 1) {
                onSkipValidation(addressesToValidate);
              } else {
                setActiveIndex((activeIndex) => activeIndex + 1);
              }
            }}
          >
            Confirm Address
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default AddressConfirmation;
