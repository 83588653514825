import { IconButtonBrandColors } from '@hallmark/web.core.buttons.icon-button';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import userZoneAddPhotoIconUS from '../../assets/user-zone-add-photo-icon-us.svg';
import userZoneAddTextIconUS from '../../assets/user-zone-add-text-icon-us.svg';
import userZoneAddWamIconUS from '../../assets/user-zone-add-wam-icon-us.svg';
import { DeliveryModes } from '../../components/address-form/address-form-types';
import { HeaderVariants } from '../../components/print-on-demand-header/print-on-demand-header-types';
import { RegionalCodesList } from '../../global-types';
import { TextEditorDrawerType } from '../../global-types/regions-list';
import colorsList from '../../styles/util.scss';
import { RegionalSchema } from '../regional-config-types';

export const configUS: RegionalSchema = {
  deliveryMode: DeliveryModes.None,
  cardEditor: {
    faceTypeText: true,
    textEditorDrawerType: TextEditorDrawerType.Side,
    lastCardFaceRoute: 'address',
  },
  cardSelectBar: {
    containerClass: 'footer',
  },
  saveProjects: {
    isSavedProjectsEnabled: true,
    saveButton: {
      type: 'LoadingButton',
      iconName: IconNames.SyncSyncInactive,
    },
    hasSignInDialog: false,
  },
  addressForm: {
    handleZipChange: false,
    shouldShowOneToManyEnvelopePreview: true,
  },
  header: {
    svgColor: IconButtonBrandColors.Purple,
    saveDialogTheme: RegionalCodesList.us,
    variant: HeaderVariants.US,
    quantityLink: true,
  },
  userZones: {
    addTextIcon: userZoneAddTextIconUS,
    addPhotoIcon: userZoneAddPhotoIconUS,
    addWAMIcon: userZoneAddWamIconUS,
    areInstructionsEnabled: false,
  },
  scssStyles: {
    appBackground: colorsList[BrandColors.EditorBackground],
    headerBackgroundMobile: colorsList[BrandColors.White],
  },
  features: {
    // POD Write a Message includes a WAM button in the toolbar and a WAM button in user zones
    isPodWamEnabledOnlyInside: true,
  },
  isAddressRegulationToastEnabled: true,
  hasUsStates: true,
};
