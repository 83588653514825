import { ImageResponse } from '../../global-types';
import { PreviewImage } from '../../global-types/images';
import { TransformedPersonalizationData } from '../../utils/helper-settings';
import { actionTypes, AppToasterState, CartUtility, Dispatch } from './app-context-types';

export const setIsTextDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_TEXT_DRAWER_OPEN,
  });
};

export const setIsSizeDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_SIZE_DRAWER_OPEN,
  });
};

export const setIsImageUploadDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_IMAGE_UPLOAD_DRAWER_OPEN,
  });
};

export const setIsImageUploadToPhotoZoneDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_IMAGE_UPLOAD_TO_PHOTOZONE_DRAWER_OPEN,
  });
};
export const setIsImageEditDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_IMAGE_EDIT_DRAWER_OPEN,
  });
};

export const setIsOrderDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_ORDER_DRAWER_OPEN,
  });
};

export const setIsWamDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_WAM_DRAWER_OPEN,
  });
};

export const setIsRotationDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_ROTATION_DRAWER_OPEN,
  });
};

export const setIsScaleDrawerOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_SCALE_DRAWER_OPEN,
  });
};

export const setAllDrawersClosed = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_ALL_DRAWERS_CLOSED,
  });
};

export const setIsToasterOpen = (dispatch: Dispatch, payload: Omit<AppToasterState, 'isOpen'>) => {
  dispatch({
    type: actionTypes.SET_IS_TOASTER_OPEN,
    payload,
  });
};

export const setIsToasterClosed = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_TOASTER_CLOSED,
  });
};

export const showLoadingScreen = (dispatch: Dispatch, title: string) => {
  dispatch({
    type: actionTypes.SHOW_LOADING_SCREEN,
    payload: title,
  });
};

export const hideLoadingScreen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.HIDE_LOADING_SCREEN,
  });
};

export const setIsDeleteConfirmationDialogOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_DELETE_CONFIRMATION_DIALOG_OPEN,
  });
};

export const setIsDeleteConfirmationDialogClosed = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_DELETE_CONFIRMATION_DIALOG_CLOSED,
  });
};

export const setIsClearConfirmationDialogOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_CLEAR_CONFIRMATION_DIALOG_OPEN,
  });
};

export const setIsClearConfirmationDialogClosed = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_CLEAR_CONFIRMATION_DIALOG_CLOSED,
  });
};

export const setIsResetConfirmationDialogOpen = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_RESET_CONFIRMATION_DIALOG_OPEN,
  });
};

export const setIsResetConfirmationDialogClosed = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.SET_IS_RESET_CONFIRMATION_DIALOG_CLOSED,
  });
};

export const setCartUtility = (dispatch: Dispatch, payload: CartUtility) => {
  dispatch({
    type: actionTypes.SET_CART_UTILITY,
    payload,
  });
};

export const setIsSystemErrorOpen = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_IS_SYSTEM_ERROR_OPEN,
    payload,
  });
};

export const setIsRedirectDialogOpen = (
  dispatch: Dispatch,
  payload: { isDialogOpen: boolean; redirectUrl: string },
) => {
  dispatch({
    type: actionTypes.SET_IS_REDIRECT_DIALOG_OPEN,
    payload,
  });
};

export const setIsProjectSaved = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_IS_PROJECT_SAVED,
    payload,
  });
};

export const addColorToMoreColorsAdded = (dispatch: Dispatch, payload: string) => {
  dispatch({
    type: actionTypes.ADD_COLOR_TO_MORE_COLORS_ADDED,
    payload,
  });
};

export const setErrorMessages = (dispatch: Dispatch, payload: string[]) => {
  dispatch({
    type: actionTypes.SET_ERROR_MESSAGES,
    payload,
  });
};

export const setExitUrl = (dispatch: Dispatch, payload: string) => {
  dispatch({
    type: actionTypes.SET_EXIT_URL,
    payload,
  });
};

export const setIsSendToMe = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_SEND_TO_ME,
    payload,
  });
};

export const setPersonalizationData = (dispatch: Dispatch, payload: TransformedPersonalizationData[]) => {
  dispatch({
    type: actionTypes.SET_PERSONALIZATION_DATA,
    payload,
  });
};

export const addPhotoTrayImages = (dispatch: Dispatch, payload: ImageResponse[]) => {
  dispatch({
    type: actionTypes.ADD_PHOTO_TRAY_IMAGES,
    payload,
  });
};

export const increasePagePhotoTrayImages = (dispatch: Dispatch) => {
  dispatch({
    type: actionTypes.INCREASE_PAGE_PHOTO_TRAY_IMAGES,
  });
};

export const deletePhotoTrayImage = (dispatch: Dispatch, photoTrayImageId: ImageResponse['photo_tray_image_id']) => {
  dispatch({
    type: actionTypes.DELETE_PHOTO_TRAY_IMAGE,
    payload: photoTrayImageId,
  });
};

export const setCsrfToken = (dispatch: Dispatch, payload: string) => {
  dispatch({
    type: actionTypes.SET_CSRF_TOKEN,
    payload,
  });
};

export const setProductQuantity = (dispatch: Dispatch, payload: string) => {
  dispatch({
    type: actionTypes.SET_PRODUCT_QUANTITY,
    payload,
  });
};

// export const setJwt = (dispatch: Dispatch, payload: string) => {
//   dispatch({
//     type: actionTypes.SET_JWT,
//     payload,
//   });
// };

export const setCardFacesLoaded = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_CARD_FACES_LOADED,
    payload,
  });
};

export const setIsSaving = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_IS_SAVING,
    payload,
  });
};

export const setIsFirstSave = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_IS_FIRST_SAVE,
    payload,
  });
};

export const setSignInDialogOpen = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_SIGN_IN_DIALOG_OPEN,
    payload,
  });
};

export const storePreviews = (dispatch: Dispatch, payload: PreviewImage[]) => {
  dispatch({
    type: actionTypes.STORE_PREVIEW_IMAGES,
    payload,
  });
};

export const setTriggeringElementRef = (
  dispatch: Dispatch,
  payload: HTMLElement | HTMLInputElement | HTMLButtonElement,
) => {
  dispatch({
    type: actionTypes.SET_TRIGGERING_ELEMENT_REF,
    payload,
  });
};

export const setIsSpellcheckDialogOpen = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_IS_SPELLCHECK_DIALOG_OPEN,
    payload,
  });
};

export const setSkipSpellcheckValidation = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_SKIP_SPELLCHECK_VALIDATION,
    payload,
  });
};

export const setCheckSpelling = (dispatch: Dispatch, payload: boolean) => {
  dispatch({
    type: actionTypes.SET_CHECK_SPELLING,
    payload,
  });
};
