import { CanvasDataTypes } from '../canvas';

/**
 * Discards the active selection of temporary input from the canvas.
 * @param {fabric.Canvas} currentCanvas - The current canvas object.
 */
export const discardTemporaryInput = (currentCanvas) => {
  const activeObject = currentCanvas.getActiveObject();
  if (activeObject?.name === CanvasDataTypes.TemporaryTAMInput || activeObject?.name === CanvasDataTypes.UserText) {
    currentCanvas.discardActiveObject();
    currentCanvas.renderAll();
  }
};
