import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { Dialog, DialogContentType, DialogFooterType } from '@hallmark/web.core.feedback.dialog';
import { BrandColors } from '@hallmark/web.styles.colors';
import { IconNames } from '@hallmark/web.styles.fonts.icomoon';
import { useInitializationDataContext } from '../../context/data-context';
import {
  ConfirmationDialogProps,
  DeleteConfirmationDialogProps,
  DeleteQuickAddressConfirmationDialogProps,
} from './confirmation-dialog-types';

export const ConfirmationDialog = ({
  domId,
  title,
  actionButtonText,
  cancelButtonText,
  contentText,
  isOpen,
  onClose,
  onConfirm,
}: ConfirmationDialogProps): React.ReactElement => {
  const { t } = useTranslation();

  const { initializedDataState } = useInitializationDataContext();
  const { isUK } = initializedDataState;

  const actionButton = (
    <Button click={() => onConfirm()} mode={ButtonModes.Primary} testId={'confirmation-dialog-action-button'}>
      {actionButtonText}
    </Button>
  );

  const cancelButton = (
    <Button mode={ButtonModes.TextLink} click={() => onClose()} testId={'confirmation-dialog-cancel-button'}>
      {cancelButtonText ? cancelButtonText : t('confirmationDialog.cancelButton')}
    </Button>
  );

  return (
    <Dialog
      id={domId}
      testId={domId}
      accentIcon={IconNames.CardFeaturesBold}
      accentIconColor={BrandColors.White}
      hasGrayBackground={false}
      type={DialogContentType.Modal}
      isOpen={isOpen}
      onClose={onClose}
      actionButton={actionButton}
      cancelButton={cancelButton}
      title={title}
      footerType={DialogFooterType.Cancel}
      closeButtonId={`${domId}-close-button`}
      locale={isUK ? 'uk' : 'us'}
    >
      {contentText || ''}
    </Dialog>
  );
};

export const DeleteConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): React.ReactElement => {
  return (
    <ConfirmationDialog
      domId="delete-confirmation-dialog"
      title="Are you sure you want to delete this?"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      actionButtonText="Delete"
    />
  );
};

export const ResetConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): React.ReactElement => {
  const contentText = "If this image isn't already saved in your photo library, you'll lose it permanently.";

  return (
    <ConfirmationDialog
      domId="reset-confirmation-dialog"
      title="Reset this image?"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      contentText={contentText}
      actionButtonText="Reset"
    />
  );
};

export const ResetTextConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): React.ReactElement => {
  const { t } = useTranslation();

  const contentText = t('resetTextDialogPhotoTextZone.content');

  return (
    <ConfirmationDialog
      domId="reset-text-confirmation-dialog"
      title={t('resetTextDialogPhotoTextZone.title')}
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      contentText={contentText}
      actionButtonText={t('resetTextDialogPhotoTextZone.actionButton')}
    />
  );
};

export const ClearConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteConfirmationDialogProps): React.ReactElement => {
  return (
    <ConfirmationDialog
      domId="clear-confirmation-dialog"
      title="Are you sure you want to clear this content?"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      actionButtonText="Clear"
    />
  );
};

export const DeleteQuickAddressConfirmationDialog = ({
  isOpen,
  onClose,
  onConfirm,
}: DeleteQuickAddressConfirmationDialogProps): React.ReactElement => {
  return (
    <ConfirmationDialog
      domId="delete-quick-address-confirmation-dialog"
      title="Delete address?"
      contentText="This will permanently remove this address from your Address Book"
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      actionButtonText="Delete"
      cancelButtonText="Cancel"
    />
  );
};
