import { useCallback, useState } from 'react';
import { ImageResponse } from '../global-types';
import { deletePhotoTrayImages } from '../services';

export const useDeletePhotoTrayImages = (
  setPhotoTrayImages: React.Dispatch<React.SetStateAction<ImageResponse[]>>,
  handleDeleteImageLocally: (photoId: string) => void,
  setIsDeleting: (isDeleting: boolean) => void,
) => {
  const [imagesToDelete, setImagesToDelete] = useState<ImageResponse[]>([]);
  const [deleteImagesConfirmationOpen, setDeleteImagesConfirmationOpen] = useState(false);

  /**
   * Calls the API to delete the photoTrayImages
   * @param photoTrayIds array of string containing the photoTrayImageIds
   * @returns Promise<void> and a 204 empty response
   */
  const handleDeletePhotoTrayImages = async (photoTrayIds: string[]) => {
    try {
      await deletePhotoTrayImages(photoTrayIds);
      photoTrayIds.forEach(handleDeleteImageLocally);
    } catch (error) {
      throw new Error(error as any);
    }
  };

  // Sets the delete confirmation dialog open/close state
  const handleOpenDeleteImagesConfirmationDialog = (isOpen: boolean) => {
    setDeleteImagesConfirmationOpen(isOpen);
  };

  const handleDeleteImagesConfirmation = useCallback(async () => {
    // Remove the images to delete from the photoTrayImages state
    setPhotoTrayImages((prevImages) => prevImages.filter((photo) => !imagesToDelete.includes(photo)));

    // Maps the photoTrayId from each image receive in imagesToDelete param
    const imagesToDeleteIds = imagesToDelete.map((image) => image.photo_tray_image_id);
    // Calls the function to delete the images
    await handleDeletePhotoTrayImages(imagesToDeleteIds);

    // Reset the imagesToDelete state
    setImagesToDelete([]);
    setIsDeleting(false);

    // Close the delete images confirmation dialog
    handleOpenDeleteImagesConfirmationDialog(false);
  }, [
    imagesToDelete,
    setPhotoTrayImages,
    setImagesToDelete,
    handleOpenDeleteImagesConfirmationDialog,
    deletePhotoTrayImages,
  ]);

  return {
    setImagesToDelete,
    deleteImagesConfirmationOpen,
    handleDeleteImagesConfirmation,
    handleOpenDeleteImagesConfirmationDialog,
  };
};
