import React, { useState } from 'react';
import LazyLoad from 'react-lazy-load';
import { IconButton, IconButtonBrandColors, IconNames } from '@hallmark/web.core.buttons.icon-button';
import { RadioCard } from '@hallmark/web.core.forms.radio-card';
import { HtmlTagOverrides, Typography, TypographyVariants } from '@hallmark/web.core.typography.typography';
import { DeleteQuickAddressConfirmationDialog } from '../../confirmation-dialog/confirmation-dialog';
import { SavedContactProps } from '../address-book-types';
import styles from './saved-contact.module.scss';

const SavedContactComponent = ({ handleDelete, contact, index, isUK, register }: SavedContactProps) => {
  const [isDeleteQuickAddressConfirmationModalOpen, setIsDeleteQuickAddressConfirmationModalOpen] = useState(false);
  return (
    <div className={styles['saved-contacts']}>
      <LazyLoad key={contact.contact_id}>
        <div role="radiogroup" aria-labelledby="radio_button_group" id="radio_button_group">
          <RadioCard
            domId={`basic-radio-card-${index}`}
            register={register('index', { required: true })}
            testId={`address-radio-card-${index}`}
            addClass={styles['radio-card']}
            value={index.toString()}
          >
            <div className={styles['contact-row']}>
              <div className={styles['name']}>
                <Typography
                  htmlTagOverride={HtmlTagOverrides.Div}
                  variant={TypographyVariants.Caption}
                  color={IconButtonBrandColors.DarkGray}
                >
                  {`${contact.first_name} ${contact.last_name}`}
                </Typography>
              </div>
              <IconButton
                icon={IconNames.TrashBold}
                click={() => setIsDeleteQuickAddressConfirmationModalOpen(true)}
                size={16}
                iconColor={IconButtonBrandColors.DarkGray}
                addClass={styles.icon}
                testId={`delete-contact-${index}`}
                altText="delete contact"
              />
            </div>
            <Typography
              htmlTagOverride={HtmlTagOverrides.Div}
              variant={TypographyVariants.Caption}
              color={IconButtonBrandColors.MediumGray}
            >
              {contact.address_line_1}
            </Typography>
            <Typography
              htmlTagOverride={HtmlTagOverrides.Div}
              variant={TypographyVariants.Caption}
              color={IconButtonBrandColors.MediumGray}
            >
              {isUK ? `${contact.city}, ${contact.zip}` : `${contact.city}, ${contact.state_code} ${contact.zip}`}
            </Typography>
          </RadioCard>
        </div>
      </LazyLoad>
      <DeleteQuickAddressConfirmationDialog
        isOpen={isDeleteQuickAddressConfirmationModalOpen}
        onClose={() => setIsDeleteQuickAddressConfirmationModalOpen(false)}
        onConfirm={() => {
          handleDelete({
            contactId: contact.contact_id,
            firstName: contact.first_name,
            lastName: contact.last_name,
          });
        }}
      />
    </div>
  );
};

export const SavedContact = React.memo(SavedContactComponent);
