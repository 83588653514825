import { useCallback } from 'react';
import { setPersonalizationData, useAppContext } from '../../../context/app-context';
import { useCardContext } from '../../../context/card-context';
import { useInitializationDataContext } from '../../../context/data-context';
import { useIsOneToMany, useLoadCardData, useSystemErrorHandling } from '../../../hooks';
import { getTransformedPersonalizationData } from '../../../utils';

type UseLoadCardAfterLoginReturn = {
  loadCardDataAfterLogin: () => void;
};
/**
 * Handles the update of cardFacesList state from card-context and personalizationData state for app-context
 * After login the contexts are re-initialized with the refresh of the page so we need to populate this states after getting the data from the loaded project
 */
export const useLoadCardAfterLogin = (): UseLoadCardAfterLoginReturn => {
  const {
    initializedDataState: { data: initializedData },
  } = useInitializationDataContext();
  const { appState, appDispatch } = useAppContext();
  const { cardState } = useCardContext();
  const { loadCardData } = useLoadCardData();
  const isOneToMany = useIsOneToMany();
  const [onSystemError] = useSystemErrorHandling();

  const loadCardDataAfterLogin = useCallback(async () => {
    const projectTypeCode = initializedData?.project_type_code;
    if (!projectTypeCode || appState.personalizationData.length > 0) {
      return;
    }
    if (cardState.cardFacesList.length > 0) {
      getTransformedPersonalizationData(cardState, projectTypeCode, isOneToMany)
        .then((personalizationData) => {
          return setPersonalizationData(appDispatch, personalizationData);
        })
        .catch((error) => {
          onSystemError({
            status: 0,
            data: {
              errors: [
                {
                  code: '0',
                  description: error,
                },
              ],
            },
          });
          throw Error(error);
        });
    }
    loadCardData();
  }, [cardState.cardFacesList, loadCardData, initializedData?.project_type_code]);

  return { loadCardDataAfterLogin };
};
