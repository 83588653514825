import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonModes } from '@hallmark/web.core.buttons.button';
import { EcardLimitDialog } from '@hallmark/web.page-components.ecard-limit-dialog';
import { RegionalCodesList } from '../../global-types';
import { ECardLimitModalProps } from './ecard-limit-modal-types';

export const ECardLimitModal = ({ onClose, isOpen }: ECardLimitModalProps) => {
  const { t } = useTranslation();
  const region = process.env.REACT_APP_THEME;

  const goToShopHallmark = () => {
    window.location.href = '/';
    onClose();
  };
  const goToJoinHallmark = () => {
    window.location.href = '/hallmarkplus-account/';
    onClose();
  };

  const ShopHallmarkButton = (
    <Button testId="shop-hallmark-btn" mode={ButtonModes.Secondary} click={goToShopHallmark}>
      {t('eCardLimit.shopHallmark')}
    </Button>
  );

  const JoinHallmarkButton = (
    <Button testId="join-hallmark-btn" mode={ButtonModes.Primary} click={goToJoinHallmark}>
      {t('eCardLimit.joinHallmark')}
    </Button>
  );

  return (
    <EcardLimitDialog
      title=""
      isOpen={isOpen}
      close={onClose}
      cancelButtonContent={ShopHallmarkButton}
      actionButtonContent={JoinHallmarkButton}
      locale={region || RegionalCodesList.us} // region will fallback to 'us' if process.env.REACT_APP_THEME is not set
    />
  );
};
