import { Contact } from '../components/address-book/address-book-types';
import { ApiResponse } from './services';

export type AddressTypeCode = 'S' | 'R';

export enum AddressTypes {
  RECIPIENT = 'recipient',
  SENDER = 'sender',
  DIGITAL = 'digital',
}

export enum PrefixForm {
  EMAIL_FORM = 'emailAddressForm',
  ADDRESS_FORM = 'addressForm',
}

export type AddressProps = {
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  state: string;
  zip: string;
};

export type BaseAddress = {
  first_name: string;
  last_name: string;
  company_name: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  state_code?: string;
  zip: string;
  country_code: string;
  address_type_code: AddressTypeCode;
};

export type DigitalAddress = {
  recipient_first_name: string;
  recipient_last_name: string;
  contact_info: string;
  sender_first_name: string;
  sender_last_name: string;
};

export type AddressResponseData = BaseAddress & {
  address_id: string;
  created_at: string;
  last_updated_at: string;
  is_verified: boolean;
};

export type SaveAddressResponse = ApiResponse<AddressResponseData>;

export type UpdateAddress = SaveAddressResponse;

export type GetAddresses = ApiResponse<AddressResponseData[] | null>;

export type Address = BaseAddress & {
  skip_usps_validation: boolean;
  send_to?: string;
  envelope?: 'blank' | 'return' | null;
};

export type AddressForm = Address & {
  isQuickAddress: boolean;
};

export type EmailAddressForm = DigitalAddress & {
  recipient_type_code: string;
};

export type QuickAddress = Omit<BaseAddress, 'address_type_code'> & {
  created_at?: string;
  last_updated_at?: string;
  is_verified?: boolean;
  contact_id?: string;
  account_id?: string;
  contact_type_code: string;
};

export type QuickAddressSubmitData = Omit<BaseAddress, 'address_type_code'> & {
  contact_type_code: string;
  skip_address_verification: boolean;
};

export type QuickAddressResponse = ApiResponse<QuickAddress>;

export type QuickAddresses = {
  contacts: Contact[];
  paging: {
    total_pages: number;
    total_items: number;
    next_page: number | null;
  };
};

export type QuickAddressesResponse = ApiResponse<QuickAddresses>;

export enum ErrorType {
  Incomplete = 'err_incomplete_address',
  Invalid = 'err_invalid_address',
}

export type FormValidation = {
  errorType: ErrorType;
  addressType: AddressTypes;
  address: AddressProps;
  step: number;
};

export type AssetFormData = {
  front_url: string;
  inside_url: string;
};

export type RecipientResponseData = {
  url: string;
};

export type SaveAssetResponse = ApiResponse<RecipientResponseData>;

// export const BulkUploadAddressFormats = '.csv, .xlsx';
export enum BulkUploadAddressFormats {
  csv = '.csv',
  xlxs = '.xlsx',
}
