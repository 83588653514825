export const helperSettingsConfig = {
  bleedInMM: 3,
  mmInPixels: 11.811,
  scalingFactor: 2,
  timerId: null, // autosave timer id used for setting and resetting the timer
  timerInterval: 60000, // autosave interval in ms
  userZoneScaleBreakpoint: 500, // threshold to scale down objects in narrow user zone
  largeUserZoneButtonSize: 88, // UK scales up buttons to this value in large user zones
  defaultUserZoneInstructionsSize: 24, // Default user zone instruction font size
  smallUserZoneInstructionsSize: 18, // Small user zone instruction font size (if zone width is less than userZoneScaleBreakpoint)
  userZoneInstructionsTopPosition: 250, // Default/fallback positioning of instructions
  minimumPpiThreshold: 150, // Minimum PPI threshold for images to prevent low-resolution images when printing the card
  printerDpi: 300, // Printer DPI (Dots per Inch) used to convert pixels to inches
};
